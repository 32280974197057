@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --typography-sans: "Open Sans";
    --typography-serif: "Bitter";
    --typography-heading: "Open Sans";
  }

  html {
    @apply text-sm antialiased;
    scroll-behavior: smooth;
  }

  body {
    @apply font-normal leading-snug md:leading-normal lg:leading-relaxed text-gray min-h-screen pt-24 bg-sand;
    cursor: default;

    * {
      //position: relative;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply text-3xl md:text-4xl dt:text-5xl font-light text-primary font-heading;
  }

  a {
    @apply underline;
  }

  footer {
    a {
      @apply hover:text-primary-light transition;
    }
  }
}

@layer utilities {
}

@layer components {
  .header {
    &--sticky {
      @apply bg-sand-light/70 backdrop-blur-sm shadow-xl;
    }
  }

  .button {
    @apply text-white bg-primary inline-flex rounded-md transition px-4 py-2 no-underline font-bold border border-transparent;

    &--light {
      @apply bg-primary-light border-white/20;
    }

    &:hover {
      @apply bg-secondary border-transparent;
    }
  }
}
